import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import _, { method } from 'lodash';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import LoadingComponent from '../../common/LoadingComponent';
import config from '../../config';
import styles from '../../styles/AttendeeStyles.module.scss';

const { sessionStatus, baseUrl, client, clientDomainRgx } = config;

const isDev = process.env.NODE_ENV === 'development';

const AttendeeLandingPage = () => {
  // loop though all sessons for client
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState(false);
  const [attendeeStyles, setAttendeeStyles] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);

    const requestPayload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: client,
        customClientDomain: clientDomainRgx.test(window.location.host) ? null : window.location.host
      })
    };
    fetch(`${baseUrl}/api/getAttendeeSessions`, requestPayload)
      .then((sessionData) => sessionData.json())
      .then((requestData) => {
        setAttendeeStyles(requestData.attendeeStyles);
        setSessions(
          requestData.sessions.filter((session) => {
            // console.log('moment', moment('2023-01-24').format('YYYY/MM/DD'));
            const isToday = !isDev ? moment.utc(session.date).isSame(moment.utc(), 'day') : true;
            return isToday && ![sessionStatus.deletedStatus].includes(session.status);
          })
        );

        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // <MobileSessionList
  //       sessions={}
  //       type={'attendeeSessions'}
  //     />
  console.log(attendeeStyles);
  if (!loading && sessions) {
    return (
      <div
        className={styles.eventsContainer}
        style={{
          '--border-color': attendeeStyles.containerBorderColor,
          '--background-color': attendeeStyles.background
        }}
      >
        <div style={attendeeStyles.styles}>
          <div className={styles.audienceHeaderLogo}>
            <img alt="logo" src={attendeeStyles.topLogo} />
          </div>

          {_.isEmpty(sessions) ? (
            <h3
              style={{
                color: attendeeStyles.headerWordingColor,
                textAlign: 'center',
                margin: 50
              }}
            >
              There are no sessions today. Please visit again soon.
            </h3>
          ) : (
            <>
              <h1 style={{ color: attendeeStyles.headerWordingColor }}>
                {attendeeStyles.headerWording}
              </h1>
              {sessions.map((session, key) => (
                <div
                  className={styles.singleEventWrapper}
                  key={key}
                  style={{
                    '--border-color': attendeeStyles.eventBorderColor,
                    '--color': attendeeStyles.eventTextColor
                  }}
                >
                  <div>
                    <p>DATE:</p>
                    <p>{moment.utc(session.date).format('MM/DD/YYYY')}</p>
                  </div>
                  <div>
                    <p>PRESENTER:</p>
                    <p>{session.presenter}</p>
                  </div>
                  <div>
                    <p>Event:</p>
                    <p>{session.location}</p>
                  </div>
                  <div>
                    <button
                      type="submit"
                      style={{
                        backgroundColor: attendeeStyles.buttonColor,
                        color: attendeeStyles.buttonWordingColor
                      }}
                      onClick={() => navigate(`/audience/${session.id}`)}
                    >
                      ENTER EVENT
                    </button>
                  </div>
                </div>
              ))}
            </>
          )}
          {/* <div className={styles.audienceFooter}>
            <img alt="logo" src={attendeeStyles.tertiaryLogo} />
          </div> */}
        </div>
      </div>
    );
  }

  return <LoadingComponent />;
};

export default AttendeeLandingPage;
